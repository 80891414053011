import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import PartnersStyle, { ImgCarroucel, Next, Prev } from '@app/components/Partners/style';
import Slider from 'react-slick';
import { FormattedMessage } from 'react-intl';

const configSlider = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 8,
  slidesToScroll: 1,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
  responsive: [
    {
      breakpoint: 1025,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
      },
    },
  ],
};

interface PropArrow {
  style?: React.CSSProperties;
  onClick?: () => void;
}
function SampleNextArrow({ style, onClick }: PropArrow) {
  return (
    <Next style={{ ...style }} onClick={onClick}>
      <svg width="19" height="30" viewBox="0 0 19 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M18.3528 16.4472L5.84474 28.6171C4.98021 29.4582 3.58226 29.4582 2.72693 28.6171L0.648393 26.5947C-0.216131 25.7536 -0.216131 24.3934 0.648393 23.5612L9.51437 14.9349L0.648393 6.30863C-0.216131 5.46747 -0.216131 4.10731 0.648393 3.27511L2.71773 1.23487C3.58226 0.393716 4.98021 0.393716 5.83554 1.23487L18.3436 13.4047C19.2173 14.2459 19.2173 15.606 18.3528 16.4472Z"
          fill="#959090"
        />
      </svg>
    </Next>
  );
}

function SamplePrevArrow(props: PropArrow) {
  const { style, onClick } = props;
  return (
    <Prev style={{ ...style }} onClick={onClick}>
      <svg width="22" height="29" viewBox="0 0 22 29" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0.749445 15.8433L15.2324 28.0133C16.2334 28.8545 17.8521 28.8545 18.8425 28.0133L21.2492 25.9909C22.2503 25.1498 22.2503 23.7896 21.2492 22.9574L10.9834 14.331L21.2492 5.70467C22.2503 4.86351 22.2503 3.50334 21.2492 2.67113L18.8532 0.63087C17.8521 -0.21029 16.2334 -0.21029 15.2431 0.63087L0.760096 12.8008C-0.251581 13.642 -0.251583 15.0022 0.749445 15.8433Z"
          fill="#959090"
        />
      </svg>
    </Prev>
  );
}

const sliderData: Array<string> = [
  'https://transferfut-public-read.s3.amazonaws.com/Logo+home+page+faltando/logo+ceara.jpg',
  'https://transferfut-public-read.s3.amazonaws.com/Logo+home+page+faltando/logo+CSA(2).jpg',
  'https://transferfut-public-read.s3.amazonaws.com/Logo+home+page+faltando/logo+Joinville(4).jpg',
  'https://transferfut-public-read.s3.amazonaws.com/Logo+home+page+faltando/logo+mafra(1).jpg',
  'https://transferfut-public-read.s3.amazonaws.com/Logo+home+page+faltando/Goias+home+page.jpg',
  'https://transferfut-public-read.s3.amazonaws.com/Logo+home+page+faltando/VIla+nova+home+page.jpg',
  'https://transferfut-public-read.s3.amazonaws.com/Logo+Clubes+Home+Page/acg+home.png',
  'https://transferfut-public-read.s3.amazonaws.com/Logo+Clubes+Home+Page/america++home.png',
  'https://transferfut-public-read.s3.amazonaws.com/Logo+Clubes+Home+Page/avai+home.png',
  'https://transferfut-public-read.s3.amazonaws.com/Logo+Clubes+Home+Page/crb+home.png',
  'https://transferfut-public-read.s3.amazonaws.com/Logo+Clubes+Home+Page/operario+home+(1).png',
  'https://transferfut-public-read.s3.amazonaws.com/Logo+Clubes+Home+Page/santa+cruz+home.png',
  'https://transferfut-public-read.s3.amazonaws.com/Logo+Clubes+Home+Page/Vilafranquense+home.png',
  'https://transferfut-public-read.s3.amazonaws.com/Logo+Clubes+Home+Page/Vitoria+home.png',
  'https://transferfut-public-read.s3.amazonaws.com/Logo+Clubes+Home+Page/volta+redonda+home+(1).png',
];

const Partners = (): React.ReactElement => (
  <PartnersStyle>
    <Container>
      <Row className="justify-content-md-center row_title">
        <Col xs="12" sm="12">
          <div className="title">
            <FormattedMessage id="landingPage.partners.title" />
          </div>
          <div className="divider">
            <div className="two"></div>
          </div>
        </Col>
      </Row>

      <Slider {...configSlider}>
        {sliderData.map((item: string, index: number) => (
          <div className="d-flex justify-content-between align-items-center" key={index}>
            <ImgCarroucel src={item} alt="it" />
          </div>
        ))}
      </Slider>
    </Container>
  </PartnersStyle>
);

export default Partners;
