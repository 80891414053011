import React from 'react';
import Head from 'next/head';

import Plans from '@app/components/Plans';
import Depositions from '@app/components/Depositions';
import Footer from '@app/components/Footer';
import Wallpaper from '@app/components/Wallpaper';
import Partners from '@app/components/Partners';
import About from '@app/components/About';
import SuccessCase from '@app/components/SuccessCases';

const IndexPage = (): React.ReactElement => {
  return (
    <>
      <Head>
        <title>Transferfut</title>
      </Head>
      <Wallpaper />
      <Partners />
      <About />
      <Plans />
      <SuccessCase />
      <Depositions />
      <Footer />
    </>
  );
};

export default IndexPage;
