import React from 'react';

import { AboutStyle, Card, Text } from './style';
import { Col, Container, Row } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

const gray = '#B9B9B9';
const yellow = '#C79852';

export default function about(): JSX.Element {
  return (
    <AboutStyle className="justify-content pt-5">
      <Container fluid>
        <Row>
          <Card
            color="#484848"
            xs="4"
            sm="4"
            className="pl-md-5 d-flex justify-content-center align-items-center flex-column"
          >
            <Col sm="8" lg="6" className="ml-md-auto">
              <Text color={gray}>+80</Text>
              <Text color={yellow}>
                <FormattedMessage id="common.clubs" />
              </Text>
            </Col>
          </Card>
          <Card color="#2d2e30" xs="4" sm="4" className="d-flex justify-content-center align-items-center flex-column">
            <Text color={gray}>+180</Text>
            <Text color={yellow}>
              <FormattedMessage id="common.agents" />
            </Text>
          </Card>
          <Card
            color="#131313"
            xs="4"
            sm="4"
            className="pr-md-5 d-flex justify-content-center align-items-center flex-column"
          >
            <Col sm="8" lg="6" className="mr-md-auto">
              <Text color={gray}>+1500</Text>
              <Text color={yellow}>
                <FormattedMessage id="common.players" />
              </Text>
            </Col>
          </Card>
        </Row>
      </Container>
    </AboutStyle>
  );
}
