import { Col } from 'react-bootstrap';
import styled from 'styled-components';

export const AboutStyle = styled.section`
  background: #000;
`;

export const Card = styled(Col)`
  background: ${(props) => props.color};
  @media (min-width: 767px) {
    padding: 25px 40px;
  }
  padding: 25px 0px;
`;

interface PropText {
  color?: string;
}
export const Text = styled.h4<PropText>`
  color: ${(props) => props.color};
  font-weight: bold;
  @media (min-width: 767px) {
    font-size: 44px;
    line-height: 52px;
  }
  font-size: 1.2rem;
  text-align: center;
`;
