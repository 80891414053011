import React from 'react';

import { Title, SubTitle, SuccessCaseStyle, Header, Img, Text, TitleH4, Item, Prev, Next } from './style';
import Slider from 'react-slick';
import { FormattedMessage, useIntl } from 'react-intl';

const configSlider = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
};

interface PropSlideData {
  id: number;
  title: string;
  description: string;
  img: React.ImgHTMLAttributes<any>;
}

interface PropArrow {
  style?: React.CSSProperties;
  onClick?: () => void;
}
function SampleNextArrow({ style, onClick }: PropArrow) {
  return (
    <Next style={{ ...style }} onClick={onClick}>
      <svg width="48" height="45" viewBox="0 0 48 45" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.728271" y="0.5" width="45.9565" height="44" rx="3.5" stroke="#817D7D" />
        <path
          d="M29.1674 23.6933L21.112 31.7487C20.5552 32.3055 19.6549 32.3055 19.1041 31.7487L17.7655 30.4101C17.2087 29.8533 17.2087 28.953 17.7655 28.4022L23.4753 22.6923L17.7655 16.9825C17.2087 16.4257 17.2087 15.5254 17.7655 14.9746L19.0982 13.6241C19.6549 13.0674 20.5552 13.0674 21.1061 13.6241L29.1615 21.6795C29.7241 22.2363 29.7241 23.1366 29.1674 23.6933Z"
          fill="white"
        />
      </svg>
    </Next>
  );
}

function SamplePrevArrow(props: PropArrow) {
  const { style, onClick } = props;
  return (
    <Prev style={{ ...style }} onClick={onClick}>
      <svg width="47" height="45" viewBox="0 0 47 45" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" y="0.5" width="45.9565" height="44" rx="3.5" stroke="#817D7D" />
        <path
          d="M17.5365 21.6795L25.5919 13.6241C26.1486 13.0673 27.0489 13.0673 27.5998 13.6241L28.9384 14.9627C29.4951 15.5195 29.4951 16.4198 28.9384 16.9706L23.2285 22.6805L28.9384 28.3903C29.4951 28.9471 29.4951 29.8474 28.9384 30.3982L27.6057 31.7487C27.0489 32.3055 26.1486 32.3055 25.5978 31.7487L17.5424 23.6933C16.9797 23.1365 16.9797 22.2362 17.5365 21.6795Z"
          fill="#979393"
        />
      </svg>
    </Prev>
  );
}

const sliderData: Array<PropSlideData> = [
  {
    id: 1,
    title: 'landingPage.successCase.titlePlayer1',
    description: 'landingPage.successCase.descriptionPlayer1',
    img: {
      alt: 'Jogador de futebol,  nome moyseis',
      src: 'img/moyseis.png',
    },
  },
  {
    id: 2,
    title: 'landingPage.successCase.titlePlayer2',
    description: 'landingPage.successCase.descriptionPlayer2',
    img: {
      alt: 'Jogador de futebol,  nome jardel',
      src: 'img/jardel.jpeg',
    },
  },
  {
    id: 3,
    title: 'landingPage.successCase.titlePlayer3',
    description: 'landingPage.successCase.descriptionPlayer3',
    img: {
      alt: 'Jogador de futebol, nome marcos',
      src: 'img/marcos.png',
    },
  },
  {
    id: 4,
    title: 'landingPage.successCase.titlePlayer4',
    description: 'landingPage.successCase.descriptionPlayer4',
    img: {
      alt: 'Zagueiro luis felipe',
      src: 'img/luis_felipe.jpeg',
    },
  },
  {
    id: 5,
    title: 'landingPage.successCase.titlePlayer5',
    description: 'landingPage.successCase.descriptionPlayer5',
    img: {
      alt: 'Jogador de futebol, nome lucas',
      src: 'img/lucas_souza.png',
    },
  },
  {
    id: 6,
    title: 'landingPage.successCase.titlePlayer6',
    description: 'landingPage.successCase.descriptionPlayer6',
    img: {
      alt: 'Jogador de futebol, nome Matheus Leal',
      src: 'img/matheus_leal.jpg',
    },
  },
  {
    id: 7,
    title: 'landingPage.successCase.titlePlayer7',
    description: 'landingPage.successCase.descriptionPlayer7',
    img: {
      alt: 'Jogador de futebol, nome Cesinha',
      src: 'img/wellington.jpg',
    },
  },
  {
    id: 8,
    title: 'landingPage.successCase.titlePlayer8',
    description: 'landingPage.successCase.descriptionPlayer8',
    img: {
      alt: 'Jogador de futebol, nome Cesinha',
      src: 'img/cesinha.jpg',
    },
  },
];

const SuccessCase = (): React.ReactElement => {
  const intl = useIntl();

  return (
    <SuccessCaseStyle>
      <div className="container">
        <Header>
          <SubTitle>
            <FormattedMessage id="landingPage.successCase.title" />
          </SubTitle>
          <Title>TRANSFERFUT</Title>
        </Header>
        <Slider {...configSlider}>
          {sliderData.map((item: PropSlideData) => (
            <Item key={item.id} className="d-flex justify-content-between px-4 pb-5 align-items-center">
              <Img className="mr-4" {...item.img} />
              <div className="ml-md-5 mt-5 mt-md-0">
                <TitleH4>{intl.formatMessage({ id: item.title })} </TitleH4>
                <Text>{intl.formatMessage({ id: item.description })}</Text>
              </div>
            </Item>
          ))}
        </Slider>
      </div>
    </SuccessCaseStyle>
  );
};

export default SuccessCase;
