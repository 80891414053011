import React, { SyntheticEvent, useEffect, useState } from 'react';
import Link from 'next/link';
import { Row, Col, Navbar, Container } from 'react-bootstrap';

import WallpaperStyle, { BackgroundImage } from '@app/components/Wallpaper/style';
import FacebookIcon from '@app/components/Icons/FacebookIcon';
import IstagramIcon from '@app/components/Icons/InstagramIcon';
import ButtonLogin from '@app/components/Navbar/buttonLogin';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { DynamicChangeLanguage } from '../ChangeLanguageDropdown/DynamicChangeLanguage';
import { FormattedMessage } from 'react-intl';

const Wallpaper = (): React.ReactElement => {
  const refDiv = React.useRef<HTMLHeadingElement>(null);
  const [sizeScreen, setSizeScreen] = useState(0);

  useEffect(() => {
    setSizeScreen(window.innerWidth);
  }, []);

  const handleClick = (ev: SyntheticEvent) => {
    ev.preventDefault();

    if (refDiv.current) {
      const y = refDiv.current?.scrollHeight;

      window.scroll({
        top: y + 50,
        behavior: 'smooth',
      });
    }
  };

  return (
    <WallpaperStyle>
      <Container fluid>
        <div className="d-flex pt-2">
          <span className="language-mobile">
            <DynamicChangeLanguage />
          </span>
          <Link href="/login">
            <div className="login-mobile mr-2">
              <FontAwesomeIcon className="icon_info" icon={faUser} size="1x" />
            </div>
          </Link>
        </div>

        <div ref={refDiv}>
          <Row className="justify-content-md-center wallpaper">
            <BackgroundImage url={sizeScreen < 768 ? '/img/bg-mobile.png' : '/img/wallpaperv2.png'}>
              <div className="container">
                <Navbar>
                  <Navbar.Collapse className="no-mobile">
                    <div className="icons d-flex align-items-center">
                      <a href="https://www.facebook.com/transferfut/" target="_blank" rel="noopener noreferrer">
                        <FacebookIcon color="#ffffff" />
                      </a>

                      <a
                        href="https://www.instagram.com/transferfutcom/?hl=pt-br"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <IstagramIcon color="#ffffff" />
                      </a>

                      <DynamicChangeLanguage />
                    </div>
                  </Navbar.Collapse>

                  <Navbar.Toggle />

                  <Navbar.Collapse className="justify-content-end logo">
                    <img src="/img/logo_branca.png" alt="logo" />
                  </Navbar.Collapse>

                  <Navbar.Collapse className="justify-content-end no-mobile">
                    <ButtonLogin />
                  </Navbar.Collapse>
                </Navbar>

                <Col xs sm="8" className="row_title">
                  <div className="title_info">
                    {sizeScreen > 768 ? <div className="divider"></div> : ''}
                    <div className="title">
                      <FormattedMessage id="landingPage.wallpaper.title" />
                    </div>
                    <div className="subtitle">
                      <FormattedMessage id="landingPage.wallpaper.subtitle" />
                    </div>

                    <a className="btn_init" onClick={handleClick}>
                      <span>
                        <FormattedMessage id="common.start" />
                      </span>
                    </a>
                  </div>
                </Col>
              </div>
            </BackgroundImage>
          </Row>
        </div>
      </Container>
    </WallpaperStyle>
  );
};

export default Wallpaper;
