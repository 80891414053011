import styled from 'styled-components';

const Container = styled.button`
  all: unset;
  border: 1px solid #c79852;
  box-sizing: border-box;
  border-radius: 5px;
  margin: 0px 26px;

  .contents-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 36px;
    padding-left: 44px;
    padding-right: 48px;

    .icon_info {
      color: #c79852;
      margin-right: 8px;
    }

    .label {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 300;
      font-size: 18px;
      line-height: 116.69%;
      color: #ffffff;
    }
  }
`;

export default Container;
