import styled, { css } from 'styled-components';

const WallpaperStyle = styled.div`
  background-color: #000000;

  .navbar {
    height: 64px;
  }

  .wallpaper {
    .img {
      position: absolute;
      max-width: 100%;
      height: auto;
    }
  }

  .container {
    max-width: 1024px;
    margin: auto;
  }

  .icons {
    a {
      padding: 0px 6px;
    }
  }

  .title_info {
    max-width: 444px;
    min-width: 280px;
    height: 880px;
    display: flex;
    flex-direction: column;

    @media (max-width: 768px) {
      margin-top: 71px;
    }

    .divider {
      background: #c79852;
      width: 154px;
      height: 4px;
      margin: 100px 0px 10px;
    }

    .title {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: bold;
      @media (min-width: 1536px) {
        font-size: 36px;
      }
      font-size: 33px;

      line-height: 40px;
      color: #ffffff;
    }

    .subtitle {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 300;
      font-size: 20px;
      line-height: 30px;
      color: #ffffff;
      margin-top: 18px;
    }

    .btn_init {
      cursor: pointer;
      margin-top: 26px;
      width: 308.48px;
      padding: 16px;
      text-align: center;
      background: #c79852;
      border: 2px solid #c79852;
      box-sizing: border-box;
      border-radius: 7px;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 23px;
      line-height: 116.69%;
      color: #000000;
      text-decoration: none;
    }
  }

  .login-mobile {
    display: none;
    cursor: pointer;
    background-color: transparent;
    position: absolute;
    right: 0;
    margin-top: -4px;
    @media (min-width: 800px) {
      margin-right: -4px;
    }
    padding: 12px 14px 8px;
    border: 1px solid #ffffff;
    box-sizing: border-box;
    border-radius: 6px;
    z-index: 99999999;

    .icon_info {
      color: #c79852;
      cursor: pointer;
    }
  }

  .language-mobile {
    display: none;
  }

  @media (max-width: 576px) {
    .login-mobile {
      display: block;
    }

    .language-mobile {
      display: block;
    }

    .logo {
      justify-content: center !important;
    }

    .no-mobile {
      display: none !important;
    }

    .wallpaper {
      .img {
        margin-top: 64px;
      }
    }

    .title_info {
      padding: 5px;
      height: 500px;

      @media (min-width: 767px) {
        text-align: center;
        align-items: center;
      }
      .title {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: bold;
        @media (min-width: 767px) {
          font-size: 34px;
          line-height: 36px;
        }
        color: #ffffff;
        font-size: 23px;
        line-height: 24px;
      }

      .subtitle {
        font-family: 'Roboto';
        font-style: normal;
        @media (min-width: 767px) {
          font-weight: 500;
        }
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #ffffff;
      }

      .btn_init {
        min-width: 240px;
        max-width: 360px;
        width: 240px;
      }
    }
  }
`;

interface PropBackground {
  url: string;
}
export const BackgroundImage = styled.div<PropBackground>`
  ${(props) =>
    props.url &&
    css`
      background-image: url(${props.url});
    `}
  height: 100vh;
  width: 100%;
  @media (min-width: 1200px) {
    background-position: center left;
  }
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 100% -26%;
`;
export default WallpaperStyle;
