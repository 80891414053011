import React from 'react';
import Link from 'next/link';

import ButtonLoginStyle from '@app/components/Navbar/buttonLogin/style';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FormattedMessage } from 'react-intl';

const ButtonLogin = (): React.ReactElement => {
  return (
    <Link href="/login">
      <ButtonLoginStyle>
        <div className="contents-buttons">
          <FontAwesomeIcon className="icon_info" icon={faUser} size="1x" />

          <div className="label">
            <FormattedMessage id="common.login" />
          </div>
        </div>
      </ButtonLoginStyle>
    </Link>
  );
};

export default ButtonLogin;
