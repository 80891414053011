import styled from 'styled-components';

const PartnersStyle = styled.div`
  background: #000;

  .control-dots {
    display: none;
  }
  .row_title {
    .title {
      margin-top: 61px;
      text-align: center;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 111%;
      letter-spacing: 0.11em;
      color: #fff;
    }
  }

  .row_log {
    padding: 40px 0px;

    .logo {
      img {
        width: 120px;
        height: 135px;
      }
    }
  }

  .divider {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin-bottom: 61px;

    .two {
      position: absolute;
      width: 94px;
      height: 4px;
      background: #c79852;
    }
  }
`;

export const ImgCarroucel = styled.img`
  margin: auto;
  width: 90px;
`;

export const Prev = styled.div`
  margin-top: 30px;
  cursor: pointer;
  position: absolute;
  z-index: 99999999;
`;

export const Next = styled.div`
  margin-top: 30px;
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
`;
export default PartnersStyle;
