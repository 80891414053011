import styled from 'styled-components';

const SuckcessCaseStyle = styled.div`
  background-color: #0b0909;
`;

export const Img = styled.img`
  box-shadow: 15px 21px 0px 2px #c79852;
  border-radius: 10px;
  @media (min-width: 767px) {
    width: 380px;
  }
  width: 95%;
  height: auto;
`;

export const Text = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 116.69%;
  color: #fff;
  padding-bottom: 40px;
`;

export const Header = styled.header`
  padding: 86px 0;
`;

export const SubTitle = styled.h4`
  font-family: 'Roboto';
  font-style: normal;
  color: #c79852;
  font-weight: bold;
  @media screen and (min-width: 767px) {
    font-size: 2.125em;
  }
  font-size: 1em;
  text-align: center;
  letter-spacing: 0.11em;
`;

export const SuccessCaseStyle = styled.section`
  background-color: #0b0909;
`;

export const TitleH4 = styled.h4`
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 163.19%;
  letter-spacing: 0.005em;
  color: #c79852;
`;

export const Title = styled.h2`
  font-weight: 300;
  @media screen and (min-width: 767px) {
    font-size: 54px;
  }
  font-size: 25px;
  line-height: 40%;
  text-align: center;
  letter-spacing: 0.11em;
  color: #fff;
`;

export const Item = styled.div`
  @media (max-width: 767px) {
    align-content: center;
    flex-direction: column;
    display: flex;
    margin: auto;
  }
`;

export const Prev = styled.div`
  position: absolute;

  @media (min-width: 767px) {
    left: 45%;
    top: 59%;
  }
  z-index: 999;
  bottom: 20px;
  left: 0px;
  cursor: pointer;

  @media (min-width: 800px) and (max-width: 810px) {
    top: -60px;
    margin: 0 -20px;
  }
`;

export const Next = styled.div`
  position: absolute;
  cursor: pointer;

  @media (min-width: 767px) {
    left: 50%;
    top: 59%;
  }
  @media (min-width: 800px) and (max-width: 810px) {
    top: -60px;
    margin: 0 15px;
  }
  right: 0px;
  bottom: 20px;
  z-index: 999;
`;

export default SuckcessCaseStyle;
