import styled from 'styled-components';

const PlansStyle = styled.div`
  cursor: pointer;
  background-color: #161616;

  .row_info {
    padding-top: 60px;
  }

  .row_content {
    padding-bottom: 100px;
    .row_card {
      display: flex;
      justify-content: center;
    }
  }

  .plan_color {
    position: absolute;
    height: 50%;
  }

  .info {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-bottom: 70px;

    .divider {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 100%;
      padding: 10px;

      .one {
        max-width: 354px;
        width: 100%;
        height: 1px;
        background: rgba(181, 181, 181, 0.32);
      }

      .two {
        position: absolute;
        width: 94px;
        height: 8px;
        background: #c79852;
      }
    }

    .title {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 36px;
      line-height: 163.19%;
      letter-spacing: -0.06em;
      color: #ffffff;
    }

    .text {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 300;
      font-size: 18px;
      line-height: 116.69%;
      text-align: center;
      color: #ffffff;
      padding-bottom: 70px;
    }
  }

  .row-color {
    position: absolute;
    height: 200px;
    width: 100%;
  }

  .card_item {
    position: relative;

    .card-image {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-image: url('/img/card_plans.png');
      background-repeat: no-repeat;
      background-size: cover;
      opacity: 0.16;
    }

    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    max-width: 440px;
    height: 98%;
    background: #080a11;
    border: 5px solid #080a11;
    box-sizing: border-box;
    box-shadow: 12px 18px 30px rgba(0, 0, 0, 0.25);
    border-radius: 7px;
    padding: 60px 15px 20px;
    margin: 20px 0px;

    .title {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: bold;
      font-size: 30px;
      line-height: 116.69%;
      color: #ffffff;
    }

    .subtitle {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 116.69%;
      color: #ffffff;
    }

    .cards_divider {
      margin: 20px 0px;
      background: #8288fe;
      width: 52.94px;
      height: 1.68px;
    }

    .card_text {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      line-height: 27.37px;
      letter-spacing: 0.005em;
      color: #ffffff;
    }

    .icon {
      position: absolute;
      bottom: 14px;
      color: #ffffff;
    }
  }

  .card_item:hover {
    border: 5px solid #c79852;
  }
`;

export default PlansStyle;
